import * as React from "react";
import { Component } from "react";
import { debounce } from "lodash";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { Dat } from "./EventDataFormat";
import { LoadingBar } from "../../../common/Components/LoadingBar"

import {
    getPostCodeBounds,
    getBoundedEventsPage,
    getCCEventsPage
} from "./EventListHelper";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";

declare var google: any;

export interface CCEventListFormProps {
    EventId?: number | string;
    Id?: number | string;
    initPlace?: string;
    Theme: string
}

interface IEventCardProps {
    startDate: string;
    endDate: string;
    eventTitle: string;
    eventDescrition?: string;
    eventUrl: string;
    latitude: number;
    longitude: number;
    location: string;
    className?: string;
    eventButtonText?: string;
}

export interface Link {
    Name?: string
    Target?: string
    Type?: number
    Udi?: string
    Url?: string
}

export interface ButtonModel  { 
    Text?: string;
    Link?: Link;
    ColorLabel?: string;
    ColorCode?: string;
    Shape?: ButtonShape;
    ButtonId?: string
}

type ButtonShape = 'retangular' | 'shape'

export const isNullOrWhitespace = (input: string) => {

    if (typeof input === 'undefined' || input == null) return true;

    return input.replace(/\s/g, '').length < 1;
}

const EventCard = (props: IEventCardProps) => {

    const localDate = moment(props.startDate).format("DD MMM YYYY");
    const weekDay = moment(props.startDate).format("dddd");

    const localTime = moment(props.startDate).format("h:mm a");
    const endTime = moment(props.endDate).format("h:mm a");
    return (
        <div className="row justify-content-center">

            <div className="col-md-12 card-margin">
                <article className={`card ${!isNullOrWhiteSpace(props.className)? props.className :"" }`}>
                    <a href={props.eventUrl}>
                        <div className="card-body event-card-body">
                            <div className="col-xs-12 col-sm-8 event-info">
                                <h3 className="event-title">
                                    {props.eventTitle.slice(0, 50)}
                                </h3>

                                
                                <p className="event-card-detail">

                                {`${weekDay}, ${localDate}`}<span className="time">, {localTime} - {endTime} </span>
                                </p>

                                
                                <p className="event-card-detail location-container">
                                    <span className="location">{props.location}</span>
                                </p>
                                
                                {props.eventDescrition ?
                                    <p className="event-description event-card-row">
                                        {props.eventDescrition}
                                    </p> : null}
                            </div>
                            <div className="col-sm-4 col-xs-12 btn-container">
                                <div className="event-join-btn btn alpnat-btn-default join-event-btn">
                                    {props.eventButtonText ? props.eventButtonText : "Read More"}
                                </div>
                            </div>
                        </div>
                    </a>
                </article>
            </div>
        </div>
    );
};

interface IEventListProps {
    eventList?: any[];
    eventListButtonText?: string;
}

interface IAppProps extends CCEventListFormProps {
    InitialPlace: string;
    ContentId: number;
    EventButtontext?: string;
    HideEventListMap: boolean;
    Theme: string;
    BottomButtonOne? : ButtonModel;
    BottomButtonTwo? : ButtonModel;

}

interface IBTNGroupProps {
    BottomButtonOne?: ButtonModel
    BottomButtonTwo?: ButtonModel
}

const BtNGroup = (props: IBTNGroupProps)=> {
    const button1 = props.BottomButtonOne;
    const button2 = props.BottomButtonTwo;
    return  <div className="bottom-btn-group">
        {button1 != null && <div className={`${button1.ColorLabel && button1.ColorLabel.toLowerCase()}-btn carousel bottom-btn btn ${button1.Shape && button1.Shape}`}>
            <a href={button1.Link && button1.Link.Url } target={button1.Link && button1.Link.Target}>{button1.Text}</a>                       
        </div>}
        {button2 != null && <div className={`${button2.ColorLabel && button2.ColorLabel.toLowerCase()}-btn carousel bottom-btn btn ${button2.Shape && button2.Shape}`}>
            <a href={button2.Link && button2.Link.Url } target={button2.Link && button2.Link.Target}>{button2.Text}</a>                       
        </div>}
    </div>
}

interface IAppState {
    postCode: string;
    map: any;
    data: any;
    error: boolean;
    errorMessage: string;
    loading: boolean;
}

const getRecordsPerPage = (theme: string)=>{
    switch(theme){
        case "wa":
            return 4;
        default:
            return 6;
    }
}

export class EventList extends Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state = {
            data: null,
            postCode: undefined,
            map: null,
            error: false,
            errorMessage: null,
            loading: false
        };
    }

    private recordsPerPage = getRecordsPerPage(this.props.Theme)

    private UpdateLoadingState = (state: boolean) => {
        this.setState({ loading: state })
    }

    private hideEventListMap = this.props.HideEventListMap;

    private handldSearch = (input: any) => {
        this.UpdateLoadingState(true)
        input.preventDefault();

        if (this.state.postCode.length != 4 || isNaN(parseInt(this.state.postCode))) {
            this.handleError("Postcode not found", true)
            this.setState({ data: null })
        }
        else {
            if (this.state.error == true) {
                this.setState({ error: false })
            }
            getPostCodeBounds(this.props.ContentId, this.state.postCode, this.recordsPerPage, this.UpdateData, this.UpdateLoadingState, this.handleError, this.state.map);
        }

    };

    private handleError = debounce((errorMsg: string, errorState: boolean) => {
        if (this.state.error != errorState) {
            this.setState({ error: errorState })
        }
        this.setState({ errorMessage: errorMsg, loading: false });
    }, 500)

    private handleInputChange = (input: any) => {
        input.preventDefault();
        this.setState({
            postCode: input.target.value
        });
    };

    private UpdateData = (data: any) => {
        this.setState({ data: data });
    };

    private getBounds = () => {
        const res = this.state.map.getBounds();

        return {
            northEastLat: res.getNorthEast().lat(),
            northEastLng: res.getNorthEast().lng(),
            southWestLat: res.getSouthWest().lat(),
            southWestLng: res.getSouthWest().lng()
        };
    };

    private handlePageClick = (data: any) => {
        //starts at 0
        let pageNum = data.selected;
        if (this.state.map != null) {
            const bounds = this.getBounds();
            getBoundedEventsPage(
                this.props.ContentId,
                pageNum,
                this.recordsPerPage,
                bounds.northEastLat,
                bounds.northEastLng,
                bounds.southWestLat,
                bounds.southWestLng,
                this.UpdateData,
                null,
                this.handleError,
                this.state.map,
            );
        } else {
            getBoundedEventsPage(
                this.props.ContentId,
                pageNum,
                this.recordsPerPage,
                null,
                null,
                null,
                null,
                this.UpdateData,
                null,
                this.handleError,
                this.state.map,
            );
        }
    };

    private getInitLocation = async (callback: (lat: number, lng: number) => void) => {
        const { InitialPlace } = this.props;
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
            { address: InitialPlace },
            (results: any, status: any) => {
                if (status === "OK") {
                    if (results[0]) {
                        var geoResult1 = results[0];

                        //for end user so they dont get confused what location has been set
                        // $("#address").val(geoResult1.formatted_address);

                        var lat = geoResult1.geometry.location.lat();

                        var lng = geoResult1.geometry.location.lng();

                        callback(lat, lng)

                    } else {
                        if (!this.state.error) {
                            this.setState({ error: true })
                        }
                        this.setState({
                            errorMessage: "No results found for initial location"
                        })

                    }
                } else {

                    if (!this.state.error) {
                        this.setState({ error: true })
                    }
                    this.setState({
                        errorMessage: "Unable to find the initial location due to: " +
                            status
                    })

                }
            }
        );


    }

    private initGoogleMapAutoComplete = async () => {

        var map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: -26.5, lng: 132.8 },
            zoom: 3.5
        });

        this.setState({
            map: map,
            loading: true
        });
        
        //set initial place
        const setMap = (lat?: number, lng?: number) => {
            getCCEventsPage(
                this.props.ContentId,
                0,
                this.recordsPerPage,
                lat,
                lng,
                this.UpdateData,
                this.UpdateLoadingState,
                map
            );
            // map.setCenter({ lat: lat, lng: lng });
            // var listener = google.maps.event.addListener(map, "idle", function () {
            //     map.setZoom(10);
            //     google.maps.event.removeListener(listener);
            // });
            // map.setZoom(10);
        }

        if (this.props.InitialPlace) {
            this.getInitLocation(setMap);
            this.setState({ map: map });
        } else {
            setMap();
        }

        const handleBoundsChanged = debounce(() => {

            const bounds = this.getBounds();
            getBoundedEventsPage(
                this.props.ContentId,
                0,
                this.recordsPerPage,
                bounds.northEastLat,
                bounds.northEastLng,
                bounds.southWestLat,
                bounds.southWestLng,
                this.UpdateData,
                null,
                this.handleError,
                map,
            );
            this.UpdateLoadingState(false)
        }, 2000)

        map.addListener(
            "bounds_changed",
            () => {
                this.UpdateLoadingState(true)
                handleBoundsChanged();
            }
        );

        this.setState({ map: map });

    }

    componentWillMount() {
        this.UpdateLoadingState(true)
    }

    googleMapRef = React.createRef()

    async componentDidMount() {

        if (this.hideEventListMap) {

            if (this.props.InitialPlace) {
                const getPage = (lat: number, lng: number) => {
                    getCCEventsPage(this.props.ContentId, 0, this.recordsPerPage, lat, lng, this.UpdateData, this.UpdateLoadingState, null);
                }
                this.getInitLocation(getPage);

            } else {
                getCCEventsPage(this.props.ContentId, 0, this.recordsPerPage, -26.5, 132.8, this.UpdateData, this.UpdateLoadingState, null);
            }
        } else {
            this.initGoogleMapAutoComplete()
        }
        this.UpdateLoadingState(false)
    }



    render(): JSX.Element {
        const EventList = (props: IEventListProps) => {


            if (props.eventList && props.eventList.length > 0) {


                return <div className={`evnet-list ${this.state.loading ? "loading" : ""}`}>{props.eventList.map((event, index) => (
                    <EventCard
                        key={index}
                        className={`event-index-${index} event-card`}
                        eventTitle={event.Name}

                        startDate={event.StartDate}
                        endDate={event.EndDate}
                        eventUrl={event.ContentUrl}
                        latitude={event.Latitude}
                        longitude={event.Longitude}
                        location={event.Location}
                        eventButtonText={props.eventListButtonText}
                    />
                ))}</div>
            } else {
                this.handleError("Sorry, there are no current events available in the area you are viewing. Please zoom out to see more events.", true)
                return <></>
            }

        }

        const Alert = () => (
            <div className="no-record-alert-info">
                <i className="icon icon-info-circle alert-icon">
                    <svg
                        viewBox="64 64 896 896"
                        data-icon="info-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z" />
                    </svg>
                </i>
                <span className="alert-message">
                    {this.state.errorMessage}
                </span>
            </div>
        );

        const eventData = this.state.data;
        const eventListOrHide =
        eventData !=null && eventData[Dat.CCEventsPaged] != null && !this.state.error ? (
                <EventList 
                    eventList={eventData[Dat.CCEventsPaged]}
                    eventListButtonText={this.props.EventButtontext}
                />
            ) : (<></>);

        const main = (
            <div className="event-list-page container-fluid">
                <div className="event-container container">
                    {!this.hideEventListMap ? (

                        <div className="row main-map justify-content-center">
                            <div id="map" />
                        </div>
                    ) : null}
                    <hr />
                    <SearchBox
                        onChange={this.handleInputChange}
                        onClick={this.handldSearch}
                        input={this.state.postCode}
                        loading={this.state.loading && !(eventData != null && eventData[Dat.CCEventsPaged] != null) }
                    />
                    <div id="calendar-events">


                        {eventListOrHide}

                        <div className="alter" >

                            {(eventData == null || (eventData[Dat.CCEventsAll] != null && Object.entries(eventData[Dat.CCEventsAll]).length === 0 &&
                                eventData[Dat.CCEventsAll].constructor === Object)) && this.state.error && !this.state.loading ? <Alert /> : null}
                        </div>                
                    </div>
                    {eventData && !this.state.error && !this.state.loading &&
                        eventData[Dat.CCEventsAll].length > this.recordsPerPage &&
                        eventData[Dat.CCEventsPaged].length > 0 ? (
                            <div className="row umb-paginate" id="react-paginate">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={<a href="#">...</a>}
                                    breakClassName={"break-me"}
                                    pageCount={
                                        eventData &&
                                        eventData[Dat.Page.PageCount]
                                    }
                                    marginPagesDisplayed={10}
                                    pageRangeDisplayed={10}
                                    onPageChange={dat => {
                                        this.handlePageClick(dat);
                                    }}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        ) : null}
                    {this.props.Theme == "wa" ? <BtNGroup BottomButtonOne={this.props.BottomButtonOne} BottomButtonTwo={this.props.BottomButtonTwo} /> : null}        

                </div>
            </div>

        );
        return main;
    }
}

interface ISearchBox {
    onChange: (input: React.ChangeEvent<Element>) => void;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>) => void;

    input: string
    loading: boolean
}

const SearchBox = (props: ISearchBox) => {

    return <div className="search-box row">
        <p className="col-sm-8 col-xs-12 search-info">
            Enter your postcode to find a nearby event:
                    </p>

        <div className="group col-sm-4 col-xs-12">
            <div className="container">



                <input id="postCode" type="text" required onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        props.onClick(event);
                    }
                }}
                    onChange={input => props.onChange(input)} />


                {/* <div className="go-icon"><i className="fa fa-arrow-right"></i></div> */}
                <div className="search-icon" onClick={input => props.onClick(input)}>
                    <i className="fa fa-search" aria-hidden="true" onClick={input => props.onClick(input)} />
                </div>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label className={!isNullOrWhitespace(props.input) ? "invisible":""}>e.g. 3000</label>
            </div>
        </div>
        <div className="col-md-12 loading-bar-container">
            {props.loading ? <LoadingBar /> : null}
        </div>
    </div>


};
